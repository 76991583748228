import React from 'react';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <a href="https://rusty-ai.azurewebsites.net/" target="_blank" rel="noreferrer">
          <Button variant="contained">
            <Typography>Ai Chat</Typography>
          </Button>
        </a>
        <a href="https://api.rust.jp.net/swagger/index.html" target="_blank" rel="noreferrer">
          <Button variant="contained">
            <Typography>API</Typography>
          </Button>
        </a>
      </header>
    </div>
  );
}

export default App;
